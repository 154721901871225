<template>
  <div class="form" v-if="accreditation.company" v-on:scroll="handleScroll">
    <form @submit.prevent="handleSubmit">
      <div class="form__header row" :class="{ 'form__header--sticky': isSticky }">
        <div class="form__header__back col-md-2 col-xs-10 offset-xs-1 offset-md-0">
          <router-link v-bind:to="accreditationDetailUrl" class="form__header__back__link">{{ $t('cancel') }}</router-link>
        </div>
        <div class="form__header__main col-12 col-xs-10 col-md-4 col-xl-5 offset-xs-1 offset-md-0">
          <h1 class="form__header__title">{{ $t('accRenewFormTitle') }}</h1>
        </div>
        <div class="form__header__utils col-12 col-xs-10 col-md-6 col-xl-5 offset-xs-1 offset-md-0">
          <button-gcr
            btnClass="btn-outline-gray btn-sm form__header__btn"
            type="button"
            :loading="saving"
            :disabled="isSAC"
            @btnclick="saveDraft()"
            label="saveDraft"
          />
          <button-gcr
            btnClass="btn-primary btn-sm btn-arrow form__header__btn"
            :disabled="isSAC"
            :loading="saving"
            type="submit"
            label="accRenewFormSubmit"
          />
        </div>
      </div>
      <!-- fin form__header -->
      <div class="form__body row">
        <div class="form__container col-xs-10 col-sm-8 col-md-6 offset-xs-1 offset-md-2">
          <h3 class="form__subtitle">{{ $t('accRenewFormAccToRenew') }}</h3>
          <div class="row">
            <div class="col form_col">
              <p>
                {{ accreditation.company.name }}<br />
                {{ $t('accRenewFormNumAccGCR') }} {{ accreditation.number }}<br />
                {{ $t('accRenewFormDateRenew') }} {{ renewalDate }}
              </p>
            </div>
          </div>

          <h3 class="form__subtitle">{{ $t('accRenewFormCompanyDetails') }}</h3>

          <div class="row">
            <div class="col form__col">
              <label for="coordinatesChanged">{{ $t('accRenewFormCompanyDetailsChange') }}</label
              ><br />
              <radio-gcr
                group="coordinatesChanged"
                v-bind:inline="true"
                :label="$t('yes')"
                :value="true"
                v-model="form.coordinatesChanged"
              />
              <radio-gcr
                group="coordinatesChanged"
                v-bind:inline="true"
                :label="$t('no')"
                :value="false"
                v-model="form.coordinatesChanged"
              />

              <div v-show="invalidCoordinatesChanged" class="invalid-feedback">{{ $t('accRenewFormFieldRequired') }}</div>

              <p class="input-desc">{{ $t('accRenewFormCompanyDetailsChangeNote') }}</p>
            </div>
          </div>

          <div class="row" v-show="form.coordinatesChanged">
            <div class="col form__col">
              <textarea-gcr
                label="accRenewFormCompanyDetailsChangeModifications"
                invalidText="accRenewFormFieldRequired"
                :submitted="invalidCoordinatesReason"
                rows="5"
                v-model="form.coordinatesChanges"
                maxlength="390"
              />
              <p class="input-desc" v-html="fieldMaxLengthNotice(390)"></p>
            </div>
          </div>

          <div class="row">
            <div class="col form__col">
              <label for="contactsChanged">{{ $t('accRenewFormContactDetailsChange') }}</label
              ><br />
              <radio-gcr group="contactsChanged" v-bind:inline="true" :label="$t('yes')" :value="true" v-model="form.contactsChanged" />
              <radio-gcr group="contactsChanged" v-bind:inline="true" :label="$t('no')" :value="false" v-model="form.contactsChanged" />

              <div v-show="invalidContactChanged" class="invalid-feedback">{{ $t('accRenewFormFieldRequired') }}</div>

              <p class="input-desc">{{ $t('accRenewFormContactDetailsChangeNote') }}</p>
            </div>
          </div>

          <div class="row" v-show="form.contactsChanged">
            <div class="col form__col">
              <textarea-gcr
                label="accRenewFormContactDetailsChangeModifications"
                invalidText="accRenewFormFieldRequired"
                :submitted="invalidContactReason"
                rows="5"
                v-model="form.contactsChanges"
                maxlength="390"
              />
              <p class="input-desc" v-html="fieldMaxLengthNotice(390)"></p>
            </div>
          </div>

          <h3 class="form__subtitle">{{ $t('accRenewFormContactInspection') }}</h3>

          <div v-for="(item, index) in form.inspectionContacts" :key="index">
            <div class="row">
              <div class="col form__col">
                <h4 class="form__smalltitle">
                  <span>{{ $t('accRenewFormFieldInspector') }}</span>
                  <inline-confirmation-button-gcr
                    btnClass="btn-delete btn-delete-sm"
                    @confirm="removeResponsible(index)"
                    :disabled="isSAC"
                    confirmText="deleteBuyerConfirmation"
                    confirmClass="btn-primary btn-sm"
                  />
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col col-sm-6 form__col">
                <input-gcr
                  label="accRenewFormFieldInspectorFirstName"
                  invalidText="accRenewFormFieldRequired"
                  :submitted="submitted"
                  type="text"
                  v-model="item.firstName"
                  maxlength="19"
                />
                <p class="input-desc" v-html="fieldMaxLengthNotice(19)"></p>
              </div>
              <div class="col col-sm-6 form__col">
                <input-gcr
                  label="accRenewFormFieldInspectorLastName"
                  invalidText="accRenewFormFieldRequired"
                  :submitted="submitted"
                  type="text"
                  v-model="item.lastName"
                  maxlength="20"
                />
                <p class="input-desc" v-html="fieldMaxLengthNotice(20)"></p>
              </div>
            </div>
            <div class="row">
              <div class="col col-sm-6 form__col">
                <input-gcr
                  label="accRenewFormFieldInspectorPhone"
                  invalidText="accRenewFormFieldRequired"
                  :submitted="submitted"
                  type="text"
                  v-model="item.phone"
                  maxlength="39"
                  :placeholder="$t('accRenewFormFieldInspectorPhonePlaceholder')"
                />
                <p class="input-desc" v-html="fieldMaxLengthNotice(39)"></p>
              </div>
              <div class="col col-sm-6 form__col">
                <input-gcr
                  label="accRenewFormFieldInspectorEmail"
                  invalidText="accRenewFormFieldRequired"
                  :submitted="submitted"
                  type="email"
                  v-model="item.email"
                  maxlength="39"
                />
                <p class="input-desc" v-html="fieldMaxLengthNotice(39)"></p>
              </div>
            </div>
            <div class="row">
              <div class="col form__col">
                <input-gcr
                  label="accRenewFormFieldInspectorPointOfClarification"
                  type="text"
                  v-model="item.details"
                  :optional="true"
                  maxlength="100"
                />
                <p class="input-desc" v-html="fieldMaxLengthNotice(100)"></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col form__col">
              <button-gcr
                label="accRenewFormAddInspectorBtn"
                type="button"
                btnClass="btn-outline-gray btn-sm btn-add"
                @btnclick="addResponsible()"
                v-if="form.inspectionContacts.length < 3"
              />
              <p class="input-desc">{{ $t('accRenewFormMaxInspectorNotice') }}</p>
            </div>
          </div>

          <h3 class="form__subtitle">{{ $t('accRenewFormLegalStatus') }}</h3>

          <div class="row">
            <div class="col form__col">
              <label for="bankruptcy">{{ $t('accRenewFormLegalStatusBankruptcy') }}</label
              ><br />
              <radio-gcr group="bankruptcy" v-bind:inline="true" :label="$t('yes')" :value="true" v-model="form.bankruptcy" />
              <radio-gcr group="bankruptcy" v-bind:inline="true" :label="$t('no')" :value="false" v-model="form.bankruptcy" />
              <div v-show="invalidBankruptcy" class="invalid-feedback">{{ $t('accRenewFormFieldRequired') }}</div>
            </div>
          </div>

          <div v-show="form.bankruptcy">
            <div class="row">
              <div class="col form__col">
                <input-gcr
                  label="accRenewFormLegalStatusBankruptcyInfo"
                  invalidText="accRenewFormFieldRequired"
                  :submitted="invalidBankruptcyReason"
                  type="text"
                  v-model="form.bankruptcyDetails"
                  maxlength="100"
                />
                <p class="input-desc" v-html="fieldMaxLengthNotice(100)"></p>
              </div>
            </div>
            <div class="row">
              <div class="col form__col">
                <label>{{ $t('accRenewFormLegalStatusBankruptcyUpload') }}</label>
                <div class="dropzone" :class="{ 'dropzone--error': invalidBankruptcyFile }">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    v-on:vdropzone-success="fileUploaded"
                    v-on:vdropzone-error="fileError"
                    v-on:vdropzone-file-added="fileAdded"
                    v-on:vdropzone-reset="fileReset"
                  ></vue-dropzone>
                </div>
                <div v-show="invalidBankruptcyFile" class="invalid-feedback">{{ $t('accRenewFormFieldRequired') }}</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col form__col">
              <label for="lawsuit">{{ $t('accRenewFormLegalStatusLegalProceeding') }}</label
              ><br />
              <radio-gcr group="lawsuit" v-bind:inline="true" :label="$t('yes')" :value="true" v-model="form.lawsuit" />
              <radio-gcr group="lawsuit" v-bind:inline="true" :label="$t('no')" :value="false" v-model="form.lawsuit" />
              <div v-show="invalidLawsuit" class="invalid-feedback">{{ $t('accRenewFormFieldRequired') }}</div>
            </div>
          </div>

          <div class="row" v-show="form.lawsuit">
            <div class="col form__col">
              <textarea-gcr
                label="accRenewFormLegalStatusLegalProceedingInfo"
                invalidText="accRenewFormFieldRequired"
                :submitted="invalidlawsuitDetails"
                rows="5"
                v-model="form.lawsuitDetails"
                maxlength="100"
              />
              <p class="input-desc" v-html="fieldMaxLengthNotice(100)"></p>
            </div>
          </div>

          <h3 class="form__subtitle">{{ $t('accRenewFormConstProject') }}</h3>

          <div class="row">
            <div class="col form__col">
              <checkbox-gcr
                label="accRenewFormConstProjectTestimony"
                invalidText="accRenewFormFieldRequired"
                :submitted="submitted"
                linkLabel="accRenewFormConstProjectTestimonyLink"
                linkTarget="_blank"
                v-bind:linkUrl="ongoingProjectUrl"
                v-model="form.certificationConstruction"
                @change="changeCheckboxValue(form.certificationConstruction)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col form__col">
              <label>{{ $t('accRenewFormConstProjectHouseForecast1') }}</label>
              <textarea-gcr
                label="accRenewFormConstProjectHouseForecast2"
                invalidText="accRenewFormFieldRequired"
                :submitted="submitted"
                rows="5"
                v-model="form.housesForecast"
                maxlength="390"
              />
              <p class="input-desc">
                <span v-html="fieldMaxLengthNotice(390)"></span><br />
                {{ $t('accRenewFormConstProjectIfNoneNotice') }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col form__col">
              <label>{{ $t('accRenewFormConstProjectCondoForecast1') }}</label>
              <textarea-gcr
                label="accRenewFormConstProjectCondoForecast2"
                invalidText="accRenewFormFieldRequired"
                :submitted="submitted"
                rows="5"
                v-model="form.condosForecast"
                maxlength="390"
              />
              <p class="input-desc">
                <span v-html="fieldMaxLengthNotice(390)"></span><br />
                {{ $t('accRenewFormConstProjectIfNoneNotice') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- fin form__body -->
      <div class="form__body--alt row">
        <div class="form__container col-xs-10 col-md-8 offset-xs-1 offset-md-2">
          <h3 class="form__subtitle">{{ $t('accRenewFormRulesTitle') }}</h3>
          <div class="row">
            <div class="col form__col">
              <checkbox-gcr
                label="accRenewFormRulesCheckbox"
                invalidText="accRenewFormFieldRequired"
                :submitted="submitted"
                v-model="accreditationRules"
                @change="changeCheckboxValue(accreditationRules)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- fin form__body--alt -->
      <div class="form__footer row">
        <div class="form__buttons col-xs-11 col-md-10 offset-xs-1 offset-md-2">
          <div class="form__buttons__left"></div>
          <div class="form__buttons__right">
            <button-gcr
              btnClass="btn-outline-gray btn-sm form__footer__btn"
              label="saveDraft"
              :loading="saving"
              :disabled="isSAC"
              @btnclick="saveDraft()"
            />
            <button-gcr
              btnClass="btn-primary btn-sm btn-arrow form__footer__btn"
              type="submit"
              label="accRenewFormSubmit"
              :disabled="isSAC"
              :loading="saving"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputGcr from '@/components/atoms/Input'
import TextareaGcr from '@/components/atoms/Textarea'
import ButtonGcr from '@/components/atoms/Button'
import InlineConfirmationButtonGcr from '@/components/molecules/InlineConfirmationButton'
import RadioGcr from '@/components/atoms/Radio'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import CheckboxGcr from '@/components/atoms/Checkbox'
import RoundCrest from '@/components/atoms/RoundCrest'
import { Dates, Urls, Consts } from '@/_helpers'
import form from '@/components/mixins/form'
import getAccreditationRenewalDraft from '@/graphql/accreditations/getAccreditationRenewalDraft.graphql'
import saveAccreditationRenewalDraft from '@/graphql/accreditations/saveAccreditationRenewalDraft.graphql'
import submitAccreditationRenewal from '@/graphql/accreditations/submitAccreditationRenewal.graphql'
import submitAccreditationDocument from '@/graphql/accreditations/submitAccreditationDocument.graphql'
import { mapGetters } from 'vuex'

require('../../utilities/form')

export default {
  components: { InputGcr, TextareaGcr, ButtonGcr, InlineConfirmationButtonGcr, RadioGcr, CheckboxGcr, vueDropzone, RoundCrest },
  mixins: [form],
  data() {
    return {
      submitted: false,
      formValid: true,
      form: {
        coordonateFirm: 'false',
        coordonateContact: 'false',
        financialSituation1: 'false',
        financialSituation2: 'false',
        certificationConstruction: false,
        inspectionContacts: []
      },
      ongoingProjectUrl: `${Urls.localizedUrl(Urls.PROJECTS)}?ct=${Consts.ONGOING_PROJECTS}`,
      accreditation: {},
      accreditationStatus: '',
      renewalDays: '',
      //certificationConstruction: false,
      accreditationRules: false,
      //docType: true,
      docType: 'BANKRUPTCY_RELEASE_CERTIFICATE',
      dropzoneOptions: {
        url: `${Consts.ROOT_API}/upload`,
        maxFilesize: 25, // Mo
        acceptedFiles: 'application/pdf',
        dictDefaultMessage: this.$t('dictDefaultMessage'),
        dictFileTooBig: this.$t('dictFileTooBig'),
        dictInvalidFileType: this.$t('dictInvalidFileType'),
        autoProcessQueue: false,
        createImageThumbnails: false,
        previewTemplate: this.dropZonePreviewTemplate(),
        timeout: Consts.UPLOAD_TIMEOUT
      },
      uploadingFile: false,
      fileIsPresent: false,
      saving: false,
      accreditationDetailUrl: Urls.localizedUrl(Urls.ACCREDITATION_DETAIL),
      responseFile: null,
      uploadedFile: null,
      uploadTimeout: null,
      uploadInProgress: false
    }
  },
  methods: {
    fieldMaxLengthNotice(fieldmaxlength) {
      return this.$t('accRenewFormMaxLength').replace('$1', fieldmaxlength)
    },
    dropZonePreviewTemplate() {
      return `<div class="dz-preview">
          <div class="dz-preview__details">
            <div class="dz-preview__name" data-dz-name></div>
            <div class="dz-preview__size" data-dz-size></div>
          </div>
          <div data-dz-errormessage></div>
        </div>`
    },
    addResponsible() {
      this.form.inspectionContacts.push({ firstName: '', lastName: '', phone: '', email: '', details: '' })
    },
    removeResponsible(index) {
      this.form.inspectionContacts.splice(index, 1)
    },
    changeCheckboxValue(item) {
      item = !item
    },
    saveDraft() {
      this.saving = true
      this.$apollo
        .mutate({
          mutation: saveAccreditationRenewalDraft,
          variables: {
            input: {
              accreditationId: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
              json: JSON.stringify({
                accreditationId: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
                bankruptcy: this.form.bankruptcy,
                bankruptcyDetails: this.form.bankruptcyDetails,
                bankruptcyReleaseFileId: this.form.bankruptcyReleaseFileId,
                condosForecast: this.form.condosForecast,
                contactsChanged: this.form.contactsChanged,
                contactsChanges: this.form.contactsChanges,
                coordinatesChanged: this.form.coordinatesChanged,
                coordinatesChanges: this.form.coordinatesChanges,
                housesForecast: this.form.housesForecast,
                inspectionContacts: this.form.inspectionContacts,
                lawsuit: this.form.lawsuit,
                lawsuitDetails: this.form.lawsuitDetails,
                certificationConstruction: this.form.certificationConstruction
              })
            }
          }
        })
        .then(data => {
          this.saving = false
          this.dirty = false
          this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'accRenewFormDraftSaved' })
          this.$router.push(`${Urls.localizedUrl(Urls.ACCREDITATION_DETAIL)}`)
        })
        .catch(error => {
          this.saving = false
        })
    },
    async handleSubmit() {
      this.submitted = true
      this.$store.dispatch(Consts.ACTION_ALERT_CLEAR_ALL_ERROR)
      if (this.validate()) {
        this.saving = true
        this.updating = true
        await this.submitDocument()

        this.$apollo
          .mutate({
            mutation: submitAccreditationRenewal,
            variables: {
              input: {
                accreditationId: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
                bankruptcy: this.form.bankruptcy,
                bankruptcyDetails: this.form.bankruptcyDetails,
                bankruptcyReleaseFileId: this.form.bankruptcyReleaseFileId,
                condosForecast: this.form.condosForecast,
                contactsChanged: this.form.contactsChanged,
                contactsChanges: this.form.contactsChanges,
                coordinatesChanged: this.form.coordinatesChanged,
                coordinatesChanges: this.form.coordinatesChanges,
                housesForecast: this.form.housesForecast,
                inspectionContacts: this.form.inspectionContacts,
                lawsuit: this.form.lawsuit,
                lawsuitDetails: this.form.lawsuitDetails
              }
            }
          })
          .then(data => {
            this.dirty = false
            this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'accRenewFormSubmited' })
            this.$router.push(`${Urls.localizedUrl(Urls.ACCREDITATION_DETAIL)}`)
          })
          .catch(error => {
            this.saving = false
            this.submitted = false
          })
      }
    },
    fileAdded() {
      this.fileIsPresent = true
      //this.submitDocument()
    },
    fileReset() {
      this.responseFile = null
      this.uploadedFile = null
      this.fileIsPresent = false
      this.uploadingFile = false
    },
    fileError(file, message) {
      if (this.uploadInProgress) {
        clearTimeout(this.uploadTimeout)

        this.$refs.myVueDropzone.removeFile(file)
        this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: message })
      }
    },
    async submitDocument() {
      if (!this.fileIsPresent)
        return

      this.uploadingFile = true

      const token = await this.$store.dispatch(Consts.ACTION_USER_REFRESHTOKEN)
      this.$refs.myVueDropzone.setOption('headers', { authorization: `Bearer ${token}` })

      if (this.responseFile) {
        return this.fileUploaded(this.uploadedFile, this.responseFile)
      } else {
        this.uploadInProgress = true
        this.uploadTimeout = setTimeout(() => this.fileTimeout(), Consts.UPLOAD_TIMEOUT)
        this.$refs.myVueDropzone.processQueue()
      }
    },
    fileTimeout() {
      this.uploadInProgress = false
      this.$refs.myVueDropzone.removeAllFiles(true)
      this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'fileUploadTimeout' })
    },
    async fileUploaded(file, response) {
      clearTimeout(this.uploadTimeout)

      this.uploadInProgress = false

      try {
        await this.$apollo.mutate({
          mutation: submitAccreditationDocument,
          variables: {
            input: {
              accreditationId: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
              documentType: this.docType,
              uploadedFileId: response.fileId
            }
          }
        })
      } catch (error) {
        this.$refs.myVueDropzone.removeFile(file)
        this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'unexpectedError' })
        this.fileIsPresent = false
        this.uploadingFile = false
        this.responseFile = response
        this.uploadedFile = file
        return
      }
      this.$refs.myVueDropzone.removeFile(file)

      if (!this.$refs.myVueDropzone.getAcceptedFiles().length) {
        this.form.bankruptcyReleaseFileId = response.fileId
        this.fileIsPresent = false
        //this.docType = false
        this.uploadingFile = false
        this.responseFile = null
        this.uploadedFile = null
        this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'documentSuccess' })
      }
    },
    validateInspectionContacts() {
      let contactsValid = true
      let i = 0

      while (contactsValid && i < this.form.inspectionContacts.length) {
        var contact = this.form.inspectionContacts[i]
        contactsValid = contact.firstName !== '' && contact.lastName !== '' && contact.phone !== '' && contact.email !== ''
        i++
      }

      return contactsValid
    },
    validate() {
      let valid = true

      if (
        this.invalidCoordinatesChanged ||
        this.invalidCoordinatesReason ||
        this.invalidContactChanged ||
        this.invalidContactReason ||
        this.invalidBankruptcy ||
        this.invalidBankruptcyReason ||
        this.invalidBankruptcyFile ||
        this.invalidLawsuit ||
        this.invalidlawsuitDetails ||
        !this.validateInspectionContacts() ||
        !this.form.certificationConstruction ||
        !this.accreditationRules ||
        !this.form.housesForecast ||
        !this.form.condosForecast
      ) {
        this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'accRenewFormGeneralErrorMessage' })
        valid = false
      }
      this.formValid = valid

      return valid
    }
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC
    }),
    renewalDate() {
      return Dates.formatDate(this.accreditation.renewalDate)
    },
    labelDay() {
      return this.renewalDays > 1 ? this.$t('days') : this.$t('day')
    },
    invalidCoordinatesChanged() {
      return this.submitted && this.form.coordinatesChanged == null
    },
    invalidCoordinatesReason() {
      return this.submitted && this.form.coordinatesChanged && !this.form.coordinatesChanges
    },
    invalidContactChanged() {
      return this.submitted && this.form.contactsChanged == null
    },
    invalidContactReason() {
      return this.submitted && this.form.contactsChanged && !this.form.contactsChanges
    },
    invalidBankruptcy() {
      return this.submitted && this.form.bankruptcy == null
    },
    invalidBankruptcyReason() {
      return this.submitted && this.form.bankruptcy && !this.form.bankruptcyDetails
    },
    invalidBankruptcyFile() {
      return this.submitted && this.form.bankruptcy && !this.fileIsPresent
    },
    invalidLawsuit() {
      return this.submitted && this.form.lawsuit == null
    },
    invalidlawsuitDetails() {
      return this.submitted && this.form.lawsuit && !this.form.lawsuitDetails
    }
  },
  apollo: {
    getcurrentaccreditation: {
      query: getAccreditationRenewalDraft,
      variables() {
        return {
          id: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID]
        }
      },
      update(data) {
        if (data.accreditation.drafts.renewalForm != null) {
          this.form = JSON.parse(data.accreditation.drafts.renewalForm.json)
        }
        this.accreditation = data.accreditation
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.dropzone {
  margin-top: 16px;
  padding: 0;
  border: 0;
  min-height: auto;
  background: transparent;

  &.dropzone--error {
    .dropzone {
      border-color: theme-color('danger');
      .dz-default u {
        color: theme-color('danger');
        &:hover {
          color: theme-color('danger');
        }
      }
    }
  }

  .dropzone {
    background-color: color('white');
    border: 2px dotted theme-color('primary');
    border-radius: 4px;
    padding: 16px;
    min-height: auto;

    &--error {
      border-color: theme-color('danger');

      .dz-default u {
        color: theme-color('danger');
        &:hover {
          color: theme-color('danger');
        }
      }
    }

    .dz-message {
      margin: 2em 0;
    }

    .dz-default u {
      color: $link-color;
      &:hover {
        color: $link-hover-color;
      }
    }

    .dz-preview {
      background: theme-color('primary');
      border-radius: 4px;
      color: color('white');
      font-size: 14px;
      padding: 8px;
      position: relative;
      display: block;
      margin: 0;
      min-height: auto;
      strong {
        color: inherit;
      }
      & + & {
        margin-top: 16px;
      }
      &__details {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
      }
      &__name {
        display: block;
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__size {
        display: block;
        flex: 0 0 auto;
        margin-left: 8px;
      }
      &__remove {
        //@include btn-default-properties;
        background: transparent;
        border: none;
        border-radius: 0;
        color: inherit;
        cursor: pointer;
        padding: 0;
        text-decoration: none;
        user-select: none;
        white-space: normal;

        display: block;
        flex: 0 0 auto;
        background: url(../../assets/close_white.svg) no-repeat 50% 40%;
        background-size: auto 12px;
        height: 1.5em;
        width: 1.5em;
        margin: 0 -3px 0 5px;
      }
      &__progress {
        border: 1px solid theme-color('faded-green');
        border-radius: 9px;
        height: 8px;
        margin: 4px 0;
        &-upload {
          background: theme-color('faded-green');
          border-radius: 9px;
          height: 6px;
          width: 0;
        }
      }
    }
  }
  &__btn {
    margin-top: 15px;
    text-align: center;
    > :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
