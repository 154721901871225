<template>
  <div><accreditation-renew-form ref="form"></accreditation-renew-form></div>
</template>

<script>
import AccreditationRenewForm from '@/components/organism/AccreditationRenewForm'

export default {
  components: { AccreditationRenewForm },
  beforeRouteLeave (to, from, next) {
    this.$refs.form.beforeRouteLeave(to, from, next)
  }
}
</script>
