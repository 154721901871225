<template>
  <div class="m-inline-confirmation-btn">
    <button-gcr type="button" @btnclick="onclick" :btnClass="btnClass" :inline="false" :group="false" />
    <div class="m-inline-confirmation-btn__confirm">
      <button-gcr
        type="button"
        v-if="showConfirmation"
        v-on-clickaway="onClickAway"
        @btnclick="onConfirmClick"
        :btnClass="confirmClass"
        :label="confirmText"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import ButtonGcr from '@/components/atoms/Button'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  data() {
    return {
      showConfirmation: false
    }
  },
  mixins: [clickaway],
  components: { ButtonGcr },
  props: {
    btnClass: {},
    confirmClass: {},
    confirmText: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onclick(evt) {
      this.showConfirmation = true
    },
    onClickAway() {
      this.showConfirmation = false
    },
    onConfirmClick(evt) {
      this.$emit('confirm', evt)
      this.showConfirmation = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.m-inline-confirmation-btn {
  //display: flex;
  position: relative;
  &__confirm {
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translate(0, -33%);
  }
}
</style>
