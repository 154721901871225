export default {
  data() {
    return {
      isSticky: false,
      dirty: false
    }
  },
  methods: {
    handleScroll() {
      const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
      this.isSticky = scrollPos > 40
    },
    change() {
      this.dirty = true
    },
    beforeRouteLeave(to, from, next) {
      if (!this.dirty || window.confirm(this.$t('confirmQuit')))
        return next()
      else
        return next(false)
    }
  },
  mounted() {
    this.$el.addEventListener('change', this.change)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$el.removeEventListener('change', this.change)
    window.removeEventListener('scroll', this.handleScroll)
  }
}
