<template>
  <div class="a-input">
    <label v-if="label" :for="id"
      >{{ $t(label) }} <span v-if="optional" class="optional">{{ $t('optional') }}</span></label
    >

    <textarea
      v-bind:value="value"
      :id="id"
      class="form-control"
      :class="{ 'is-invalid': invalid }"
      @input="onInput"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows"
      :maxlength="maxlength"
    />
    <div v-show="invalid" class="invalid-feedback">{{ $t(invalidText) }}</div>
  </div>
</template>

<script>
export default {
  directives: {},
  props: {
    invalidText: {},
    label: {},
    value: {},
    submitted: {},
    disabled: {},
    placeholder: {
      default() {
        return ''
      }
    },
    optional: {
      default() {
        return false
      }
    },
    rows: {
      default() {
        return 3
      }
    },
    id: {
      default() {
        return `input-${this._uid}`
      }
    },
    maxlength: {}
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.value
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
