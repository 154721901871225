<template>
  <div class="custom-control custom-radio" :class="{ 'custom-control-inline': inline }">
    <input type="radio"
           class="custom-control-input"
           :id="id"
           :name="group"
           :value="value"
           :checked="state"
           :disabled="disabled"
           @change="onChange" />
    <label class="custom-control-label" :for="id" v-html="label"></label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    group: {},
    label: {},
    value: {},
    disabled: {},
    modelValue: {},
    id: {
      default() {
        return `radio-${this._uid}`
      }
    },
    inline: {
      default() {
        return false
      }
    },
    validationFeedback: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    state() {
      return this.value === this.modelValue
    }
  },
  methods: {
    onChange() {
      this.toggle()
    },
    toggle() {
      this.$emit('input', this.state ? '' : this.value)
    },
    input() {
      return this.$el.querySelector('input')
    }
  },
  watch: {
    validationFeedback(message) {
      this.input().setCustomValidity(message)
    }
  },
  mounted() {
    this.input().setCustomValidity(this.validationFeedback)
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.custom-radio {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: #639113;
      border-radius: 8px;
    }
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: gray('gray-750');
}
</style>
