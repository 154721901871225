<template>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" :checked="checked" :id="id" @change="onChange" />
    <label class="form-check-label" :for="id">
      {{ $t(label) }} <br v-if="linkUrl && linkLabel" /><a v-if="linkUrl && linkLabel" :href="linkUrl" :target="linkTarget"
        >{{ $t(linkLabel) }}
      </a></label
    >
    <div v-show="invalid" class="invalid-feedback">{{ $t(invalidText) }}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {},
    label: {},
    id: {
      default() {
        return `checkbox-${this._uid}`
      }
    },
    linkLabel: {},
    linkUrl: {},
    linkTarget: {},
    invalidText: {},
    submitted: {}
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.checked
    }
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.was-validated .form-check-input:valid ~ .form-check-label {
  color: gray('gray-750');
}
</style>

